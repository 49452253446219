export default function () {
  var url = location.href; //获取url中"?"符后的字串
  var urlParams = new Object();
  if (url.includes('?')) {
    var strs = url.split('?')[1].split('&');
    for (var i = 0; i < strs.length; i++) {
      urlParams[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
  }
  return urlParams;
}
